body {
  margin: 0 auto;
  height: 100vh;
}

.cincoacertos {
  background-color: #282c34;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  height: 100%;
  text-align: center;
}

.cincoacertos h1, h2, h3, h4, h5 {
  text-align: center;
  position: relative;
  color: #EEE200;
  z-index: 2;
}

.cincoacertos a {
  color: #EEE200;
  text-decoration: none;
}

.cincoacertos a:hover {
  color: #EEE200;
  text-decoration: underline;
}

.body-anhanguera {
  background-color: #EBE3D8;
  color: #444444;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  height: 100%;
  text-align: center;
  padding: 5px;
}

.body-anhanguera h1 {
  text-align: center;
  position: relative;
  color: #F05A22;
  z-index: 2;
}

.bg-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 0.35;
  z-index: 1;
}

.fade-in {
  transition: opacity 0.5s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.typeahead-form-group {
  position: relative;
}

.typeahead-list-group {
  position: absolute;
  width: 100%;
  z-index: 100;
  height: auto;
  max-height: 250%;
  overflow: auto;
}

.typeahead-list-group-item {
  padding: 0.3rem 1.3rem;
  background-color: #fff;
}

.typeahead-list-group-item:hover {
  cursor: pointer;
  background: #646464;
  color: #fff;
}

.typeahead-spinner-container {
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 250px;
}

.pagination {
  justify-content: center;
  align-items: center;
}

.pagination > li > a {
  background-color: white;
  color: #646464;
}

.pagination > li > a:hover {
  color: #646464;
  text-decoration: none;
}

.pagination > .active > span {
  background-color: #646464 !important;
  border-color: #646464 !important;
}

.modal {
  color: #444444;
}
